.project-card-box {
  /* Basic box styling */
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  border: 1px solid #000000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 1vh; /* Add spacing between boxes */
  margin-left: 1rem;
  width: 20vw; /* Set width relative to viewport width */
  height: 65vh; /* Set height relative to viewport height */
  border-radius: 50px;
  float: left;
}

/* Additional styling for elements within the box */
.project-image {
  width: 90%;
  height: 25%; /* Adjust as needed */
  object-fit: cover; /* Maintain aspect ratio */
  margin-bottom: 1rem; /* Add spacing between image and details */
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  border-radius: 1rem;
}

.project-details {
  /* ... */
}

.projects-list {
  display: table; /* Change from display: inline; to display: flex; */
  margin-right: 2vw;
}

@media (max-width: 1000px) {
  .projects-list {
    flex-direction: column;
  }
  .project-card-box {
    width: auto;
    height: auto; /* Adjust height to fit content */
    font-size: 2.9vw;
  }
}
