.navbar {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 0.1vh solid #000;
  height: 10;
}

.navbar-logo {
    height: 15vh; /* Adjust as needed */
    margin-right: 5vw; /* Adjust spacing */
    /*box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);*/ /* Shadow to elevate the logo */
}

.navbar ul {
    display: flex;
    flex-grow: 0.8; /* Expand `ul` to fill available space */
}


.navbar li {
  display: inline;
  font-size: 4vh;
  flex-grow: 0.1; /* Distribute space equally among `li` elements set to 1 */
  /*border: 1px solid #000000;*/
  /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);*/
  height: 100%;
}

@media (max-width: 1000px) {
  .navbar li {
    /*font-size: 1rem;*/ /* Smaller font size for mobile screens */
  }
}

.navbar a {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
}

.navbar a:hover {
  color: #ddd;
}
