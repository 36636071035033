.App {
  background-image: url('../public/background1.jpg');
  background-position: center;
  background-size: cover;
  color: #000000;
}

.App h1{
  /*margin-left: 2rem;*/
}

.info-box {
  /* Basic box styling */
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 1px solid #000000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 2vh; /* Add spacing between boxes */
  width: 60vw;
  height: auto;
  margin-right: 10vw;
  border-radius: 2vw;
}

.App link {
  color: #61dafb;
}

.App #personal-image {
  height: 40vmin; /* Adjust as needed */
  width: 40vmin; /* Adjust as needed */
  border-radius: 10px;
}

.unstyled-link {
  text-decoration: none;
  color: inherit;
}